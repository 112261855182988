<template>
  <div>
    <h2 class="mb-2">Aguardando próxima janela de contratação</h2>
    <b-card>
      <b-row class="center">
        <b-col cols="1">
          <img class="img-icon" src="@/assets/images/out_of_business_time.svg" />
        </b-col>
        <b-col cols="7">
          <div class="ml-4">
            <h5>Sua agenda de antecipação está em atualização</h5>
            <p class="mt-1">Informamos que o horário para antecipação de recebíveis de
            cartões de crédito é de segunda a sexta-feira,
            <strong>das {{startDateHours}}:{{startDateMinutes}} às {{endDateHours}}:{{endDateminutes}} horas.</strong> Pedimos que aguarde a abertura da próxima
            janela de contratação para realizar sua solicitação.</p>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="component-box-un mw">
            <div class="d-flex justify-content-between">
              <span class="text-color bold center">Status da janela de contratação</span>
              <div class="d-flex align-items-center">
                <span class="badge badge-light-danger border-danger badge-pill mr-1">Fechada</span>
                <feather-icon
                  icon="InfoIcon"
                  size="18"
                  color="#093272"
                  class="space-elements-10"
                  id="tooltip-info-icon"
                />
                <b-tooltip class="blue-tip" target="tooltip-info-icon" triggers="hover" placement="right">
                  Horário permitido para agendamento de antecipação de recebíveis
                  de cartões de crédito é de segunda a sexta-feira, das {{startDateHours}}:{{startDateMinutes}} às {{endDateHours}}:{{endDateminutes}} horas.
                </b-tooltip>
              </div>
            </div>
            <div class="x-separator"></div>
            <div class="d-flex justify-content-between">
              <p class="text-color normal mb-0 text-align-end">Tempo restante até a próxima janela:</p>
              <div class="d-flex align-items-center">
                <feather-icon
                    icon="ClockIcon"
                    size="18"
                    color="#FC065E"
                    class="mr-1"
                  />
                <h4 class="text-color semi-bold space-elements-6 mb-0">{{ formatedCountdown }}</h4>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import http from '@/services/http';
import { BLink, BCard, BRow, BCol, BBadge, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BBadge,
    BTooltip
  },
  data() {
    return {
      countdown: 0,
      startDateHours: '0',
      startDateMinutes: '0',
      endDateHours: '0',
      endDateminutes: '0'
    }
  },
  async beforeMount() {
    await http.get('/cerc/verifica_horario_cerc').then(res => {
      if(res.data) {
        this.$router.push({ name: "dashboard"})
      }
    })

    await http.get('/cerc/horarios_disponiveis').then(res => {
      let splitedRes = res.data[0].split(' ')

      this.startDateHours = splitedRes[3].split(':')[0]
      this.startDateMinutes = splitedRes[3].split(':')[1]
      this.endDateHours = splitedRes[5].split(':')[0]
      this.endDateminutes = splitedRes[5].split(':')[1].split(',')[0]
    })

    const startDate = new Date();
    startDate.setHours(this.startDateHours);
    startDate.setMinutes(this.startDateMinutes);

    const endDate = new Date();
    endDate.setHours(this.endDateHours);
    endDate.setMinutes(this.endDateminutes);

    var date = new Date()
    if(date >= startDate && date < endDate) {
      this.countdown = (endDate - date) / 1000
    } else {
      this.countdown = (startDate - date) / 1000
    }
  },
  mounted() {
    const stopCountdown = setInterval(() => {
      this.countdown -= 1
      if (!this.countdown) {
        clearInterval(stopCountdown)
      }
      if(this.countdown === 0) {
        this.$router.push({ name: "dashboard"})
      }
    }, 1000)
  },
  computed: {
    formatedCountdown() {
      var date = new Date(0);
      date.setSeconds(this.countdown);
      var timeString = date.toISOString().substring(11, 19);
      return timeString
    }
  },
}
</script>

<style lang="scss">
.component-box-un {
  background-color: rgba(252, 6, 94, 0.1);
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #FC065E;
}
.tooltip-inner {
    background-color: #66788B;
}
.x-separator {
  border-top: solid rgba(252, 6, 94, 0.1) 1px;
  margin-top: 16px;
  margin-bottom: 13px;
}
.center {
  align-items: center;
  place-self: center;
}
</style>